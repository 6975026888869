<!-- 我的需求 -->
<template>
  <div>
    <el-card>
      <div class="topsearch">
        <div class="display">
          <div>关键词</div>
          <el-input
            size="small"
            placeholder="名称/工程名称"
            v-model="ruleForm.key"
          >
          </el-input>
        </div>
        <div class="display">
          <div>车辆类型</div>
          <el-cascader
            :options="carStatusList"
            @change="carStatusChange"
            v-model="carValue"
            :props="optionProps"
            size="small"
            style="width: 50%"
            ref="cascaderAddr"
          >
          </el-cascader>
        </div>
        <div class="display">
          <div>品牌</div>
          <el-select
            size="small"
            v-model="ruleForm.vehicleBrandId"
            placeholder="品牌"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btns">
          <el-button
            size="small"
            type="primary"
            class="submit"
            @click="inputChange"
          >
            搜索
          </el-button>
          <el-button
            size="small"
            type="primary"
            class="submit"
            @click="resetCli"
          >
            重置
          </el-button>
        </div>
      </div>
      <div class="topsearch">
        <div>所在地</div>
        <div class="select_box">
          <el-select
            v-model="ruleForm.shopAdressProvince"
            placeholder="请选择省"
            @change="provinceChange"
            size="small"
          >
            <el-option
              v-for="(item, index) in provinceList"
              :key="index"
              :label="item.cityName"
              :value="item.cityId"
            ></el-option>
          </el-select>
          <el-select
            v-model="ruleForm.shopAdressCity"
            placeholder="请选择市"
            @change="cityChange"
            size="small"
          >
            <el-option
              v-for="(item, index) in cityList"
              :key="index"
              :label="item.cityName"
              :value="item.cityId"
            ></el-option>
          </el-select>
          <el-select
            v-model="ruleForm.shopAdressArea"
            placeholder="请选择区"
            @change="areaChange"
            size="small"
          >
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.cityName"
              :value="item.cityId"
            ></el-option>
          </el-select>
          <div class="status_box">
            <div>状态</div>
            <el-select
              v-model="ruleForm.status"
              placeholder="状态"
              size="small"
              style="width: 60%"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <el-scrollbar class="list" v-if="tableData.length > 0">
        <div class="item" v-for="(item, index) in tableData" :key="index">
          <div class="flex">
            <div>
              <div class="title">{{ item.projectName }}</div>
              <div class="code">编号：{{ item.projectTypeCode }}</div>
            </div>
            <div class="status">
              {{
                item.status == 0
                  ? '编辑中'
                  : item.status == 1
                  ? '待审核'
                  : item.status == 2
                  ? '审核通过'
                  : item.status == 3
                  ? '审核拒绝'
                  : item.status == 4
                  ? '已发布'
                  : item.status == 5
                  ? '进行中'
                  : item.status == 6
                  ? '已完成'
                  : '已取消'
              }}
            </div>
          </div>
          <div v-for="(v, i) in item.details" :key="i" class="items">
            <div class="tag">
              <div>机械名称：{{ v.vehicleName }}</div>
              <div>品牌/型号：{{ v.vehicleBrand }}/{{ v.vehicleModel }}</div>
              <div>租赁方式：{{ v.leaseType == 0 ? '小时租' : v.leaseType == 1 ? '日租' : v.leaseType == 2 ? '月租' : '工作量' }}</div>
              <div>进场日期：{{ v.workTimeStart }}</div>
              <div>
                地址：{{ item.projectProvince }}{{ item.projectCity
                }}{{ item.projectCountry }}{{ item.projectAddress }}
              </div>
            </div>
            <div class="price">￥{{ v.vehicleOffer }}元/{{v.leaseUnit}}</div>
            <div class="price">x{{ v.vechileNumber }}</div>
          </div>
          <div class="btn" style="text-align: right; margin-top: 10px">
            <el-button
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isLog"
              >查看日志</el-button
            >
            <el-button
              @click="issueNeed(item.id)"
              type="primary"
              size="small"
              v-show="item.button.isPublish"
              class="submit"
              >发布</el-button
            >
            <el-button
              @click="edit(item)"
              type="primary"
              size="small"
              v-show="item.button.isUpdate"
              class="submit"
              >编辑</el-button
            >
            <el-button
              @click="cancel(item.id)"
              type="primary"
              size="small"
              v-show="item.button.isCancel"
              class="submit"
              >撤销需求</el-button
            >
            <el-button
              @click="deleteNeed(item.id)"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isDelete"
              >删除</el-button
            >
          </div>
        </div>
      </el-scrollbar>
      <el-empty :image-size="200" v-else></el-empty>
      <el-pagination
        @current-change="handleCurrentChange"
        background
        :page-size="ruleForm.pageSize"
        layout=" prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  getCityTree,
  getCitySelect,
  vehicleTypeTree,
  getRequireOrder,
  getBrand,
  deleteNeed,
  issueNeed,
  cancelNeed,
} from '@/api/user'
import { debounce } from '@/utils/validate'
export default {
  data() {
    return {
      tableData: [],
      carStatusList: [],
      carValue: [],
      brandList: [],
      optionProps: {
        value: 'vehicleTypeId',
        label: 'vehicleTypeName',
      },
      ruleForm: {
        page: 1,
        pageSize: 10,
        key: '',
        firstLevelTypeId: '',
        secondLevelTypeId: '',
        vehicleBrandId: '',
        projectCityCode: null,
        projectCountryCode: null,
        projectProvinceCode: null,
        status: null,
      },
      total: 0,
      provinceList: [],
      cityList: [],
      areaList: [],
      statusList: [
        { id: 0, name: '暂存' },
        { id: 1, name: '待审核' },
        { id: 2, name: '审核通过' },
        { id: 3, name: '审核拒绝' },
        { id: 4, name: '已发布' },
        { id: 5, name: '进行中' },
        { id: 6, name: '已完成' },
        { id: 7, name: '已取消' },
      ],
    }
  },
  mounted() {
    if(this.$route.query.type){
      this.ruleForm.status = Number(this.$route.query.type)
    }
    this.getRequireOrder()
    this.getCityTree()
    this.vehicleTypeTree()
    getBrand().then((res) => {
      this.brandList = res.data
    })
  },
  methods: {
    getRequireOrder() {
      getRequireOrder(this.ruleForm).then((res) => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    vehicleTypeTree() {
      vehicleTypeTree().then((res) => {
        this.carStatusList = res.data
      })
    },
    carStatusChange(value) {
      this.ruleForm.firstLevelTypeId = value[0]
      this.ruleForm.secondLevelTypeId = value[1]
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data
      })
    },
    provinceChange(e) {
      let provinceObj = {}
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectProvinceCode = provinceObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data
      })
    },
    cityChange(e) {
      let cityObj = {}
      cityObj = this.cityList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectCityCode = cityObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data
      })
    },
    areaChange(e) {
      let countyObj = {}
      countyObj = this.areaList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectCountryCode = countyObj.cityId
    },
    handleCurrentChange(val) {
      this.ruleForm.page = val
      this.getRequireOrder()
    },
    inputChange: debounce(function () {
      this.getRequireOrder()
    }, 300),
    edit(v) {
      // this.$store.commit('user/SET_FORMDATA', v)
      this.$router.push({
        path: '/addNeed',
        query: {
          type: 2,
          id: v.id,
        },
      })
    },
    deleteNeed(id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteNeed(id).then((res) => {
            this.$message.success('删除成功')
            this.getRequireOrder()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    issueNeed(id) {
      this.$confirm('即将发布需求, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          issueNeed(id).then((res) => {
            this.$message.success('发布成功')
            this.getRequireOrder()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    cancel(id) {
      this.$confirm('撤销需求, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          cancelNeed(id).then((res) => {
            this.$message.success('撤销成功')
            this.getRequireOrder()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },

    // 重置
    resetCli() {
      this.ruleForm = {
        page: 1,
        pageSize: 10,
      }
      this.carValue = []
      this.getRequireOrder()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-table {
  margin: 10px 0;
}
.el-pagination {
  text-align: right;
}
.btns {
  margin-left: auto;
  display: flex;
}
.topsearch {
  width: 100%;
  display: flex;
  align-items: center;
  .select_box {
    flex: 1;
    display: flex;
  }
  .status_box {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .display {
    display: flex;
    align-items: center;

    .el-input {
      width: 60%;
    }
    .el-select {
      width: 60%;
    }
    div {
      margin-right: 20px;
    }
  }
}

.topsearch:nth-child(2) {
  margin-top: 20px;
  .el-select {
    width: 17%;
    margin-left: 20px;
  }
}
.el-scrollbar__thumb {
  display: none;
}
.el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}
.list {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  height: 500px;
  border-radius: 5px;

  .item:hover {
    color: #3C60BC;
    background-color: #f5f7fa;
  }
  .item {
    margin-bottom: 10px;
    padding: 10px;
    transition: all 0.2s;
    border-radius: 5px;
    border-bottom: 1px solid #ebeef5;
    .flex {
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: 600;
        font-size: 16px;
      }
      .code {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
      }
      .status {
        color: orange;
      }
    }
    .items {
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tag {
        font-size: 12px;
        color: #999;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        div {
          margin: 0 0 10px 10px;
        }
      }
      .price {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
